<template>
<ul class="nav-three w-1200 bg-fff">
  <li v-for='(item,idx) in list' :key='idx' :class="{active:curIdx==idx}" @click='itemClick(idx)'>{{item}}</li>
</ul>
</template>

<script>
export default {
  props:{
    list:{
      type:Array,
      default:[]
    },
    curIdx:{
      type:Number,
      default:0
    }
  },
  methods:{
    itemClick(idx){
      this.$emit('toggleItem',idx)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/params.less';

.nav-three{
  //text-align: center;
  letter-spacing: 1px;
  font-size: 16px;
  li{
    .cus;
    padding:8px 14px;
    margin:25px 5px 10px 5px;
    line-height: 1;
    &.active{
      background-color: @main1;
      color: #fff;
    }
  }
}
</style>

<template>
<div class=" bg-f">
  <tag-big :navData="navDaa"></tag-big>
  <div class="w-1200 clear" :class="{'cont-warp':curData.imageUrl}">
    <div :class="{'pc-wid75':curData.imageUrl}">
      <div class="sec-content" v-if="curData.children==null||curData.children.length==0" v-html="curData.content"></div>
      <!--三级内容-->
<!--      <p class="dec" v-if="curData.categoryDesc">{{curData.categoryDesc}}</p>-->
      <div class="sec-content" v-if="curData.categoryDesc" v-html="curData.categoryDesc"></div>
      <ul class="nav-three" v-if="curData.children!=null&&curData.children.length!=0">
        <li @click="threeIdx=idx"
            v-for="(item,idx) in curData.children" :key="idx"
            :class="{active:threeIdx==idx}">{{item.categoryName}}</li>
      </ul>
      <div class="three-content"
           v-if="curData.children!=null&&curData.children.length!=0&&curData.children[threeIdx].content"
           v-html="curData.children[threeIdx].content">
      </div>
    </div>
    <div class="wid-25" v-if="curData.imageUrl">
      <img  class="no-show" :src="curData.imageUrl">
      <a v-if="curData.rightLink" :href="curData.rightLink" target="_blank">
        <img :src="curData.imageUrl">
      </a>
      <img v-else :src="curData.imageUrl">
    </div>
  </div>
</div>
</template>

<script>
import {
  SecContent
} from '@/api/index.js'
import TagBig from '@/components/tag_big'
import NavThree from '@/components/nav_thress.vue'

export default {
  components:{
    TagBig,NavThree
  },
  data(){
    return {
      daa:{
				categoryName:'',
        categoryId:'',
				children:[]
			},
      navDaa:{
        categoryName:'',
        categoryId:'',
				children:[]
      },
      curData:{
        content:"",
        children:[]
      },
      threeIdx:0,
      threeContent:"",
      cid:this.$route.query.cid
    }
  },
  watch: {
    $route(newVal,oldVal) {
      this.threeIdx=0
      this.daa={
        categoryName:'',
        categoryId:'',
        children:[]
      }
      this.curData={
        content:"",
        children:[]
      }
      let flag=false
      if(oldVal.query.fid!=newVal.query.fid) flag=true
      this.getData(flag)
    }
  },
  created(){
    this.getData(true)
  },
  methods:{
    getData(flag){
      this.cid=this.$route.query.cid
      SecContent(this.$route.query.cid).then(res=>{
        if(res){
          this.daa=res
          res.children.forEach(ele=>{
            if(ele.categoryId==this.$route.query.cid){
              this.curData=ele
              console.log(this.curData)
            }
          })
          if(flag){
            this.navDaa=res;
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/params.less';
.wid-25{
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  img{
    position: absolute;
    width: auto;
    height: auto;
    top: 70px;
    &.no-show{
      position: initial;
      opacity: 0;
    }
  }
}
.pc-wid75{
  width: 75%;
  box-sizing: border-box;
  float: left;
  min-height: calc(100vh - 440px);
}
.cont-warp{
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: #DCDCDC;;
    position: absolute;
    left: 75%;
    top: 0;
    z-index: 2;
  }
}
.sec-content,.three-content{
  padding:0 12px;
  //  padding-right:40px;
}
p.dec{
  font-size: 14px;
  line-height: 28px;
  color: #323232;
  // padding:0 20px;
  padding-top: 20px;
  //text-indent: 2em;
}
.nav-three{
  letter-spacing: 1px;
  font-size: 16px;
  li{
    .cus;
    padding:6px 14px;
    margin:25px 0;
    line-height: 1;
    &.active{
      background-color: @main1;
      color: #fff;
    }
  }
}
@media screen and (max-width: 1024px) {
  .wid-25{
    display: none;
  }
  .pc-wid75{
    width: 100%;
    border: none;
    min-height: auto;
  }
  .cont-warp:before{
    display: none;
  }
  img.banner-3-img{
    display: none;
  }
  .nav-three{
    padding:10px 22px;
    li{
      margin:4px 0;
      width: 50%;
      .box;
    }
  }
  .three-content,.sec-content{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: justify;
  }
}
</style>
